.datetime{
  padding: 16px;
  background-color: #f1f1f1;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  font-size: 32px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}