.weight-label-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.card-container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}
.weight-label{
  width: 30%;
  font-size: 30pt;
  /* font-size: large; */
}
.weight-value{
  width: 65%;
}
.value-container{

}
.weight-value{
  font-size: 48pt;
  font-weight: 600;
  color: #D2000F;
}
.weight-unit{
  color: #4D050B;
  font-size: 32pt;
}
.order-detail-container{
  padding: 15px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, rgb(217, 246, 50), rgb(251, 230, 36));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 240px;
  margin-top: 24px;
}
.iccard-label{
  font-size: 32px;
  font-weight: 600;
  /* width: 30%; */
  text-align: left;
  margin-right: 15px;
}
.weight-detail-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}
.iccard-container{
  width: 100%;
}
