.page{
  font-family: 'PingFang SC';
  position: relative;
}
.top-section{
  padding: 16px;
  margin-bottom: 77px;
}
.login-container{
  padding: 200px 80px;
}
/* .login-form{

}
.form-item{

} */
h2 {
  text-align: center;
}
.form-label{
  font-size: 24px;
  margin: 16px 0 8px 0;
}
/* .input-container{

} */
.input{
  padding: 16px;
  border: 1px solid #f1f1f1;
  width: 100%;
  border-radius: 5px;
  font-size: 32px;
}
.actions{
  margin-top: 24px;
}
.btn-submit{
  width: 100%;
  padding: 10px;
  border: 1px solid blue;
  background-color: blue;
  color: white;
  font-size: 24px;
  border-radius: 5px;
}




.loginContainer{
  width: 100%;
  /* padding: 100px; */
}
.form{
  padding: 20px;
}
.formItem{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start
}
.label{
  padding: 10px 0px;
}
.input{
  padding: 10px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.actions{
  padding: 20px;
}


.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.content{
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.err-msg{
  color: red;
  padding: 8px 0;
}

.weight-container{
  padding: 15px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, rgb(248,236,188), rgb(250,205,36));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 240px;
  /* width: 100%; */
}

.order-id{
  font-size: 32px;
  font-weight: 600;
  color: #D2000F;
}
.weight-content{
  text-align: center;
  width: 100%;
}
.weight{
  color: #D2000F;
  font-size: 120px;
  font-weight: bolder;
}
.unit{
  color: #4D050B;
  font-size: 60px;
}

.lose-network{
  text-align: center;
  margin: 100px 0;
}
/*  广告机分辨率: 720x1280
 */
@media only screen and (max-width:720px){
  /* body{
    background-color: #D2000F;
  } */
  .weight-container{
    margin-top: 24px;
  }
}
@media only screen and (min-width:721px){
  /* body{
    background-color: yellow;
  } */

  .station-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .weight-container{
    width: 100%;
    margin-left: 24px;
  }
  .station-container{
    /* margin-bottom: 36px; */
  }
}





.scale-indicator{
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: breath 3s infinite ease-in-out alternate;
}

@keyframes breath {
  0% {
    opacity: .2;
    /* width: 10px;
    height: 10px; */

  }
  50% {
    opacity: 1;
    /* width: 20px;
    height: 20px; */
  }
  to {
    opacity: .2;
    /* width: 10px;
    height: 10px; */
  }
}
/* .card-container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}
.weight-label{
  width: 30%;
  font-size: large;
}
.weight-value{
  width: 65%;
}
.value-container{

}
.weight-value{
  font-size: 48pt;
  font-weight: 600;
  color: #D2000F;
}
.weight-unit{
  color: #4D050B;
  font-size: 32pt;
}
 */
