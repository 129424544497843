

.user-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  font-size: 32px;
}
.label{
  width: 30%;
  text-align: left;
}
.user-name{

  margin-left: 24px;
  font-weight: bolder;
}

.user{
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.avatarContainer{
  height: 56px;
  width: 56px;
}
.avatarImage{
  height: 56px;
  width: 56px;
  border-radius: 10px;
}
