.station-container{
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  /* background-color:  */
  border-radius: 10px;
  background-image: linear-gradient(to bottom, rgb(239,239,243), rgb(254,171,0));
  width: 100%;
  /* margin-bottom: 36px; */
  min-height: 240px;
}
.logo-container{
  width: 145px;
  height: 145px;
  padding: 20px;
}
.logo-image{
  width: 145px;
  height: 145px;
}

.merchant-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 24px;
  width: 100%;
}
.merchant-label{
  font-size: 30px;
}
.merchant-name{
  font-size: 32px;
  text-align: left;
}
.warehouse-name{
  font-size: 50px;
  font-weight: bolder;
}
