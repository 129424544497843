
@media only screen and (max-width: 720px){
  .box-connected{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .qrcode-container{
    min-height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .qrcode-label{
    font-size: 60px;
    margin-bottom: 36px;
  }
}
@media only screen and (min-width: 800px){
  .box-connected{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .qrcode-container{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .qrcode-label{
    font-size: 60px;
    margin-bottom: 36px;
    /* margin-right: 80px; */
  }
}

.box-offline{
  padding: 20px 40px;
  background-color: red;
  font-size: xx-large;
  color: white;
  border-radius: 10px;
}

